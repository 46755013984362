import React, { useState } from 'react';

const ContactMap = () => {
  const locations = [
    { name: "Kingdom of Saudi Arabia", url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3577.732505148774!2d49.98635097520102!3d26.270343677035783!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e361e7089cde4cb%3A0xff26c71f1f8cc0e6!2sCNS%20Arabia!5e0!3m2!1sen!2seg!4v1738619570619!5m2!1sen!2seg"},
    { name: "USA", url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3445.844939736219!2d-97.74229892464591!3d30.26999897481073!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8644b5a732223e7b%3A0x76575f05601c8fce!2sSAN%20JACINTO%20BUILDING%2C%20814%20San%20Jacinto%20Blvd%20STE%20303%2C%20Austin%2C%20TX%2078701%2C%20USA!5e0!3m2!1sen!2seg!4v1738619729837!5m2!1sen!2seg"},
    { name: "Canada", url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9365601.73915857!2d-139.5155813!3d51.4134686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5378d4cfdded7a97%3A0x95cdb124598041b4!2s51%C2%B024%2748.5%22N%20114%C2%B001%2740.1%22W!5e0!3m2!1sen!2sus!4v1694614902855!5m2!1sen!2sus" },
    { name: "Iraq", url: "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3334.661219522364!2d44.32261607548292!3d33.30152497344884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzPCsDE4JzA1LjUiTiA0NMKwMTknMzAuNyJF!5e0!3m2!1sen!2seg!4v1738619876272!5m2!1sen!2seg" },
    { name: "Kuwait", url: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3489.795953759003!2d48.11204907530314!3d28.993416875469556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3fcf0106174be06f%3A0xb65c230f451df87e!2sGopco%20Industrial%20Gas%20Production%20Co.%20W.L.L!5e0!3m2!1sen!2seg!4v1738620017797!5m2!1sen!2seg" },
  ];

  const [currentMap, setCurrentMap] = useState(locations[0].url);

  return (
    <div className="w-[95%] mx-auto py-[2.5rem] text-center md:text-left">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-[48px] h-full">
        <div className="flex flex-col items-center h-full">
          <div className="grid grid-cols-3 gap-4 lg:grid-cols-5 xl:grid-cols-5 mb-[24px]">
            {locations.map((location, index) => (
              <button
                key={index}
                onClick={() => setCurrentMap(location.url)}
                className="px-[12px] py-[5px] text-[14px] border border-gray-500 text-gray-500 rounded-md hover:bg-primary hover:text-white focus:outline-none focus:border-red-500 focus:text-red-500 active:bg-red-500 active:border-red-500 active:text-white transition-colors duration-300"
              >
                {location.name}
              </button>
            ))}
          </div>
          <div className="flex justify-center w-full h-full">
            <iframe title="map" src={currentMap} width="100%" height="100%" allowFullScreen="" loading="lazy" className="border-none rounded-2xl"></iframe>
          </div>
        </div>

        <div className="flex flex-col items-start h-full">
          <form className="w-full h-full">
            <div className="mb-[48px]">
              <label htmlFor="name" className="block text-left text-[20px] text-secondary mb-1">Name</label>
              <input id="name" type="text" className="w-full px-4 py-1 border-b border-gray-300 focus:outline-none focus:border-primary" />
            </div>

            <div className="mb-[48px]">
              <label htmlFor="email" className="block text-left text-[20px] text-secondary mb-1">Email</label>
              <input id="email" type="email" className="w-full px-4 py-1 border-b border-gray-300 focus:outline-none focus:border-primary" />
            </div>

            <div className="mb-[48px] grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label htmlFor="company" className="block text-left text-[20px] text-secondary mb-1">Company</label>
                <input id="company" type="text" className="w-full px-4 py-1 border-b border-gray-300 focus:outline-none focus:border-primary" />
              </div>
              <div>
                <label htmlFor="position" className="block text-left text-[20px] text-secondary mb-1">Position</label>
                <input id="position" type="text" className="w-full px-4 py-1 border-b border-gray-300 focus:outline-none focus:border-primary" />
              </div>
            </div>

            <div className="mb-[48px]">
              <label htmlFor="phone" className="block text-left text-[20px] text-secondary mb-1">Phone</label>
              <input id="phone" type="tel" className="w-full px-4 py-1 border-b border-gray-300 focus:outline-none focus:border-primary" />
            </div>

            <div className="mb-[48px]">
              <label htmlFor="message" className="block text-left text-[20px] text-secondary mb-1">Message</label>
              <textarea id="message" className="w-full px-4 py-1 border-b border-gray-300 focus:outline-none focus:border-primary" rows="5"></textarea>
            </div>

            <button className="flex w-auto px-[48px] py-[13px] rounded-[8px] bg-primary text-[#FFF]">Send</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactMap;