import React from 'react'
import overview from '../images/wwastewater.png';

const CPSAInfo = () => {
  return (
    <div className='w-[95%] mx-auto px-[16px] py-[2rem] text-center md:text-left xl:px-[24px] lg:px-[24px]'>
   
    <div className="flex flex-col md:flex-row justify-between items-center gap-x-20">
      {/* Second Column: Image */}
      <div className="md:w-1/2 flex items-center justify-center order-1 md:order-2 mb-[1.5rem]">
        <img src={overview} alt="Descriptive text" />
      </div>

      {/* First Column: Headline, Text, and Button */}
      <div className="md:w-1/2 mb-6 md:mb-0 text-center md:text-left order-2 md:order-1">
        {/* Heading */}
        <h2 className="text-[16px] xl:text-[24px] lg:text-[24px] text-start font-bold">
        Water and Wastewater Management
        </h2>

    <ul className='list-disc list-inside'>
          {/* First Paragraph with ellipses */}
          <li className="my-[2rem] text-[16px] text-start">
          Water Injection Pipelines: In water injection systems, particularly for oil recovery, PSC technology guarantees robust and leak-proof connections, essential for maintaining system pressure and efficiency.  </li>

        {/* Second Paragraph with ellipses */}
        <li className="mt-[2rem] text-[16px] text-start">
        Wastewater Treatment: The corrosion-resistant properties of the PSC system make it an ideal choice for pipelines in wastewater treatment plants, where exposure to corrosive substances is common.   </li>

    </ul>
      </div>
    </div>
  </div>
  )
}

export default CPSAInfo
