import React from "react";
import logo from "../images/footerlogo.svg";

import { Link } from "react-router-dom";


const servicesLinks = [
  { name: "CNSA", path: "services-cnsa" },
  { name: "CPSA", path: "cpsa" },
  { name: "CCSA", path: "ccsa" },
  { name: "CWSA", path: "cwsa" },
];



const FooterTwo = () => {
  return (
    <>
      <div className="relative my-[0px] lg:my-[18rem] xl:my-[18rem] w-[95%] mx-auto ">
        <div
          className="
 
    bg-primary 
    w-full 
    max-w-[1240px] 
    mx-auto 
    relative 
    right-0 
    left-0 
    rounded-[24px] 
    text-center 
    text-[#FFF] 
    xl:absolute 

    lg:absolute

    pt-20    
    lg:pt-[80px]    
    pb-14    
    lg:pb-[55px]    
    px-10    
    
  "
          style={{
            top: "calc(50% - 260px)",
          }}
        >
          <h2 className="font-bold text-[40px]">
            Work with the Trusted Leader in Chemical Cleaning Services In Saudi
            Arabia
          </h2>
          <p className="mt-[16px] mb-[48px] mx-auto">
          From Saudi Arabia to Dubai, Abu Dhabi, and beyond, Continuous Chemical Services Arabia has spent over a decade at the forefront of chemical cleaning – and we are just getting started. We have the experience, team, and technology to tackle any chemical cleaning requirements, and we look forward to making your project our next success story.
          </p>
          <Link
            to="contact-us"
            className="bg-secondery text=[16px] text-[#FFFF] px-[40px] py-[18px] rounded-[50px]"
          >
            Contact Us
          </Link>
        </div>
      </div>

      <footer className="w-full bg-vanish text-white pt-[2rem] pb-[2rem] text-center xl:text-start xl:pt-[190px] lg:text-start lg:pt-[190px] mt-[40px]">
        {" "}
        {/* Set footer as relative */}
        <div className="w-[95%] mx-auto px-[16px] lg:px-[24px] xl:px-[24px]">
          <div className="flex flex-col md:flex-row justify-between">
            {/* First Column */}
            <div className="flex-col items-center justify-center md:justify-start mb-4 md:mb-0 ">
              <img
                src={logo}
                alt="logo csarabia"
                className="m-auto xl:ml-0 lg:ml-0"
              />
              <p className="my-[2rem] w-[302px] text-secondery sm:mx-auto xs:mx-auto">
                Building a legacy of service excellence and technical precision
                for the future of Saudi Arabia’s oil & gas sector.{" "}
              </p>
     
            </div>

            {/* Second Column with 3 Sub-columns */}
            <div className="flex flex-col md:flex-row justify-between w-full md:w-auto gap-8 lg:gap-[6rem] xl:gap-[6rem]">
              {/* Services Column */}
              <div className="flex flex-col mb-4 md:mb-0">
                <h3 className="font-semibold mb-2 text-secondery text-[18px]">
                  Services
                </h3>
                <ul className='flex justify-between gap-[2rem] my-[2rem]'>
                  {servicesLinks.map((link, index) => (
                    <li key={index}>
                      <Link
                        to={link.path}
                        className="text-secondery text-[14px] hover:text-primary ease-in-out"
                      >
                        {link.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>



       
            </div>
          </div>

          <div className="w-[100%] h-[1px] bg-[#B8B8B8] my-[3rem]"></div>

          <p className="text-secondery text-center">
            © Copyright 2024, All Rights Reserved by Continuous Services Arabia
          </p>
        </div>
      </footer>
    </>
  );
};

export default FooterTwo;
