import React from "react";
import overview from "../images/fflushing.png"; 



const CWSAMarket = () => {
  return (
    <div className='w-[95%] mx-auto px-[16px] py-[2rem] text-center md:text-left xl:px-[24px] lg:px-[24px]'>
     <div className="flex flex-col md:flex-row justify-between items-center gap-x-20">
       {/* Second Column: Image */}
     
 
       {/* First Column: Headline, Text, and Button */}
       <div className="md:w-1/2 mb-6 md:mb-0 text-center md:text-left order-2 md:order-1">
         {/* Heading */}
         <h2 className="text-[16px] xl:text-[24px] lg:text-[24px] text-start font-bold">
         Pipeline and Process Water Flushing:
         </h2>
 
     <ul className='list-disc list-inside'>
           {/* First Paragraph with ellipses */}
           <li className="my-[2rem] text-[16px] text-start">
           Our specialized flushing services use high-quality water treatments to remove debris, scale, and contaminants from pipelines and process systems. </li>
       
 
         {/* Second Paragraph with ellipses */}
        
     </ul>
       </div>
       <div className="md:w-1/2 flex items-center justify-center order-1 md:order-2 mb-[1.5rem]">
         <img src={overview} alt="Descriptive text" />
       </div>
     </div>
   </div>
  );
};

export default CWSAMarket;
