import React from 'react'
import overview from '../images/petrochemical.png';

const CPSASection = () => {
  return (
    <div className='w-[95%] mx-auto px-[16px] py-[2rem] text-center md:text-left xl:px-[24px] lg:px-[24px]'>
   
    <div className="flex flex-col md:flex-row justify-between items-center gap-x-20">
      {/* Second Column: Image */}
      <div className="md:w-1/2 flex items-center justify-center order-1 md:order-1 mb-[1.5rem]">
        <img src={overview} alt="Descriptive text" />
      </div>

      {/* First Column: Headline, Text, and Button */}
      <div className="md:w-1/2 mb-6 md:mb-0 text-center md:text-left order-2 md:order-2">
        {/* Heading */}
        <h2 className="text-[16px] xl:text-[24px] lg:text-[24px] text-start font-bold">
        Chemical and Petrochemical Industries
        </h2>

    <ul className='list-disc list-inside'>
          {/* First Paragraph with ellipses */}
          <li className="my-[2rem] text-[16px] text-start">
          Chemical Transportation: In chemical transportation, the need for safe and secure pipeline connections is paramount. PSC technology offers a reliable solution, minimizing the risk of leaks and contamination.  </li>

        {/* Second Paragraph with ellipses */}
        <li className="mt-[2rem] text-[16px] text-start">
        Petrochemical Processing: In environments where hazardous materials are processed, the PSC system&#39;s ability to prevent corrosion and maintain structural integrity is invaluable. </li>

    </ul>
      </div>
    </div>
  </div>
  )
}

export default CPSASection
