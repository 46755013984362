import React from "react";
import overview from "../images/overview.png";

const ServicesCNSASection = () => {
  return (
    <div className=" shadow-md rounded-[24px] bg-vanish p-10 my-[2rem]  px-[16px]  w-[95%] mx-auto  py-[2.5rem] text-center md:text-left xl:px-[24px] lg:px-[24px]">
      <div className="flex flex-col md:flex-row justify-between items-center gap-x-20">
        {/* Second Column: Image */}
        <div className="md:w-1/2 flex items-center justify-center order-1 md:order-2 mb-[1.5rem]">
          <img src={overview} alt="Descriptive text" />
        </div>

        {/* First Column: Headline, Text, and Button */}
        <div className="md:w-1/2 mb-6 md:mb-0 text-center md:text-left order-2 md:order-1">
          {/* Heading */}
          <h2 className=" text-[16px]  text-start">
          As the premier leader in nitrogen services, we possess unparalleled expertise in delivering sophisticated nitrogen solutions for the oil and gas industry.
          </h2>

          {/* Lorem ipsum text */}
          <p className="my-[2rem] text-[16px]  text-start">
          In partnership with Canadian Nitrogen Services and Abdulla Fouad Energy Services, Continuous Nitrogen Services Arabia sets the gold standard for the region’s oil, gas, petrochemical, and processing sectors.
          </p>

          <p className="text-[16px]  text-start">
          While others strive to meet expectations, we are committed to exceeding them comprehensively.           </p>

          <p className=" mt-[2rem] text-[16px]  text-start">
          Continuous Nitrogen Services Arabia integrates time-tested wisdom with cutting-edge industry advancements to ensure the highest standards of
          safety and operational integrity.          </p>

          <p className=" mt-[2rem] text-[16px]  text-start">
          Serving a diverse network of local and international clients, we are dedicated to enhancing safe working environments through improved pipeline integrity and efficiency.        </p>
        </div>
      </div>
    </div>
  );
};

export default ServicesCNSASection;
