import React from 'react';
import overview from '../images/cpsalm.png';


const CPSAMarket = () => {
  return (
    <div className='w-[95%] mx-auto px-[16px] py-[2rem] text-center md:text-left xl:px-[24px] lg:px-[24px]'>
      <h1 className='text-[30px] font-bold mb-[2rem]'> Services </h1>
      <div className="flex flex-col md:flex-row justify-between items-center gap-x-20">
        {/* Second Column: Image */}
        <div className="md:w-1/2 flex items-center justify-center order-1 md:order-1 mb-[1.5rem]">
          <img src={overview} alt="Descriptive text" />
        </div>

        {/* First Column: Headline, Text, and Button */}
        <div className="md:w-1/2 mb-6 md:mb-0 text-center md:text-left order-2 md:order-2">
          {/* Heading */}
          <h2 className="text-[16px] xl:text-[24px] lg:text-[24px] text-start font-bold">
          Oil and Gas Industry
          </h2>

      <ul className='list-disc list-inside'>
            {/* First Paragraph with ellipses */}
            <li className="my-[2rem] text-[16px] text-start">
            Onshore and Offshore Platforms: The PSC system excels in both onshore and offshore oil and gas pipelines, providing unparalleled safety and reliability in challenging environments. Its capability to maintain coating integrity and prevent internal corrosion is especially valuable in these critical settings.   </li>

          {/* Second Paragraph with ellipses */}
          <li className="mt-[2rem] text-[16px] text-start">
          Hydrocarbon Transportation: The PSC system ensures the safe and efficient transportation of hydrocarbons, mitigating the risks associated with traditional welding, such as leaks and structural weaknesses.   </li>

      </ul>
        </div>
      </div>
    </div>
  );
};

export default CPSAMarket;
